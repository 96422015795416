







import { defineComponent } from 'vue-demi';
import SchematicsTable from '../components/SchematicsTable.vue';

export default defineComponent({
  components: {
    SchematicsTable,
  },
});
